const firebaseConfig = {
  projectId: "the-maranello",
  appId: "1:256709634054:web:ce22e15c83f2fe1f3d7b87",
  databaseURL:
    "https://the-maranello-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "the-maranello.appspot.com",
  locationId: "us-central",
  apiKey: "AIzaSyBq3Z9MGoxzjdCuyVWg3V0nPLx9NqHtvdg",
  authDomain: "the-maranello.firebaseapp.com",
  messagingSenderId: "256709634054",
  measurementId: "G-LHG3E8F68B",
};

export default firebaseConfig;
